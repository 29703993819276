.scroll::-webkit-scrollbar {
    width: 7px;
}

.scroll::-webkit-scrollbar-track {
    background: #292929;
}

.scroll::-webkit-scrollbar-thumb {
    background: #a3a3a7;
    border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: #bebec1;
}

@media screen and (max-width: 575px) {
    .scroll::-webkit-scrollbar {
        width: 5px;
    }
}