.sidebar__ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 1rem;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
}

.sidebar__li:hover .sidebar__icon {
    background: #00a3ff;
    color: #ffffff;
}

.sidebar__icon {
    margin-right: 10px;
    background: #0e131e;
    border-radius: 4px;
    padding: 7px;
    color: #ffffff;
    width: 29px;
    height: auto;
    margin-left: 20px;
}

.submenu_wrapper {
    overflow: hidden;
    transition: height 0.3s;
}

.submenu {
    list-style-type: none;
    background-color: #2e2d3b;
    font-size: 16px !important;
    padding-left: 60px;
}

.submenu_element {
    height: 30px;
    margin: 10px 0px 0px 0px;
    padding: 5px 0px 0px 0px;
}

.dropdown_icon {
    margin: 4px 5px 0px 0px;
    transition: rorate 0.3s;
}

.collapsed_menu_element:hover .collapsed_submenu {
    display: block;
}

.collapsed_menu_element {
    width: 45px;
    height: 45px;
    text-align: center;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.collapsed_menu_element_has_children:hover {
    background-color: #2e2d3b;
}

.collapsed_submenu {
    position: absolute;
    top: 0px;
    left: 45px;
    list-style-type: none;
    display: none;
    width: 200px;
    background-color: #2e2d3b;
    z-index: 2;
    padding: 0px;
    border-radius: 0px 10px 10px 10px;
    overflow: hidden;
}

.collapsed_submenu_element {
    margin: 0px;
    padding: 0px;
    height: 45px;
}

.cse_link:hover {
    background-color: #383841;
}

.cse_link:hover .cse_link_text {
    color: #00a3f2;
}

.cse_link {
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 10px;
}

.cse_link_text {
    font-size: 14px;
    color: white;
    text-align: left;
    padding-top: 10px;
}
